import { NgModule } from "@angular/core";
import { JustForFunComponent } from "./just-for-fun.component";

@NgModule({
    providers: [

    ],
    declarations: [
        JustForFunComponent
    ],
    exports: [
        JustForFunComponent
    ]
})
export class JustForFunModule {}