import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewChecked, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

interface Message {
    sentByMe: boolean;
    text: string;
}

interface Chat {
    user: string,
    messages: Message[]
}

@Component({
    selector: 'app-chat-area',
    templateUrl: './chat-area.component.html',
    styleUrls: ['./chat-area.component.scss']
})
export class ChatAreaComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('messageInput') messageInput: ElementRef;
    @ViewChild('messagesContainer') messagesContainerRef: ElementRef;
    @ViewChild('header') headerRef: ElementRef;
    @Output() chatClosed = new EventEmitter<boolean>();
    @Input() set selectedFriend(friend) {
        this.selectFriendToChat(friend);
    }
    formGroup = new FormGroup({
        chatInput: new FormControl()
    });
    subscription: Subscription;
    currentFriendSelected: string;
    inputHeight: number;
    chats: Chat[] = [
        {
            user: 'ChatTestBot',
            messages: [
                { sentByMe: false, text: 'Say Something and I will send a test response!' }
            ]
        }
    ];
    currentChat: Chat;
    newMessageAdded: boolean;
    showSend: boolean;

    ngOnInit() {
        this.subscription = this.formGroup.controls.chatInput.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            this.showSend = this.formGroup.controls.chatInput.value as boolean;
            this.clearLineBreaksFromInput();
        });
    }
    ngAfterViewChecked() {
        if (this.newMessageAdded) {
            this.messagesContainerRef.nativeElement.scrollTop = this.messagesContainerRef.nativeElement.scrollHeight;
            this.newMessageAdded = false;
        }
    }
    selectFriendToChat(friend: string) {
        if (friend) {
            const foundChat = this.chats.find(item => item.user === friend);
            if (foundChat) {
                this.currentChat = foundChat;
            } else {
                let newChat = { user: friend, messages: [] };
                this.chats.push(newChat);
                this.currentChat = newChat;
            }
            this.currentFriendSelected = this.currentChat.user;
        }
    }
    sendChatMessage() {
        if (this.formGroup.controls.chatInput.value) {
            this.newMessageAdded = true;
            this.currentChat.messages.push({
                sentByMe: true,
                text: this.messageInput.nativeElement.value
            });
            // if chatting with test bot, send a test response message
            if (this.currentChat.user === 'ChatTestBot') {
                setTimeout(() => {
                    this.currentChat.messages.push({
                        sentByMe: false,
                        text: 'This is a test response!'
                    });
                    this.newMessageAdded = true;
                }, 1000)
            }
            this.showSend = false;
            this.messageInput.nativeElement.style.height = this.inputHeight.toString() + 'px';
            this.formGroup.reset({
                chatInput: ''
            });
        }
    }
    chatClose() {
        this.currentFriendSelected = null;
        this.chatClosed.emit(true);
    }
    scrollToTarget($event) {
        // $event.preventDefault();
        // TODO Find a better way to override apple auto scroll on text focus
        // shutting this off for now, had jenky behavior on tablet view
        // setTimeout(() => {
        //     this.messageInput.nativeElement.scrollIntoView();
        // }, 250);
    }
    inputAreaAdjust() {
        let target = this.messageInput.nativeElement;
        if (!this.inputHeight) {
            this.inputHeight = target.offsetHeight;
        }
        target.style.height = '0';
        target.style.height = target.scrollHeight + 'px';
        if (target.offsetHeight < this.inputHeight) {
            target.style.height = this.inputHeight.toString() + 'px';
        }
    }
    clearLineBreaksFromInput() {
        const chatInput = this.formGroup.controls.chatInput;
        if (chatInput.value === '\n') {
            chatInput.reset();
        }
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}