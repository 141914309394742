import { NgModule } from "@angular/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragAndDropComponent } from "./drag-and-drop.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule
    ],
    declarations: [
        DragAndDropComponent
    ],
    exports: [
        DragAndDropComponent
    ]
})
export class DragAndDropModule {}