<div #htmlElement>
    <ng-container *ngIf="navigationLinks.subLinks?.length > 0; else noSubLinks" >
        <div class="panel-header">
            <a href="#" (click)="$event.preventDefault()">
                {{navigationLinks.label}} <i class="fa fa-caret-down"></i>
            </a>
        </div>
        <div class="collapse selections-list" [id]="id">
            <ul>
                <li *ngFor="let link of navigationLinks.subLinks">
                    <div class="selection-body">
                        <a [routerLink]="link.path">{{link.label}}</a>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-template #noSubLinks>
        <a [routerLink]="navigationLinks.path">
            {{navigationLinks.label}}
        </a>
    </ng-template> 
</div>