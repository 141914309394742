import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StateServcie } from "src/app/app-common/services/state-service.service";

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html'
})
export class AddressFormComponent {
    @Output() setFieldValidity = new EventEmitter<any>();
    @Output() clearFieldValidity = new EventEmitter<string>();
    @Input() formGroup: FormGroup;
    @Input() formErrors: any;
    _stateService: StateServcie;
    
    constructor(private stateService: StateServcie) {
        this._stateService = stateService;
    }
}