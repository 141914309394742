import { Component } from "@angular/core";
import { LookAndFeelService } from "src/app/app-common/services/look-and-feel.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    constructor(public lookAndFeelService: LookAndFeelService) {}
}