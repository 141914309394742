import { NgModule } from "@angular/core";
import { DropdownPanelComponent } from './dropdown-panel/dropdown-panel.component';
import { CommonModule } from '@angular/common';
import { NavbarAccordion } from './navbar-accordion/navbar-accordion.component';
import { RouterModule } from "@angular/router";

@NgModule({
    declarations: [
        DropdownPanelComponent,
        NavbarAccordion
    ],
    exports: [
        DropdownPanelComponent,
        NavbarAccordion
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class ComponentsModule {

}