import { Component, Input, ViewChild, ElementRef, HostListener } from "@angular/core";
import { NavigationLinks } from 'src/app/layout/header/header-navigation-links';
declare var $ : any;

@Component({
    selector: 'app-dropdown-panel',
    templateUrl: './dropdown-panel.component.html',
    styleUrls: ['./dropdown-panel.component.scss']
})
export class DropdownPanelComponent {
    @Input() id: string;
    @Input() navigationLinks: NavigationLinks;
    @ViewChild('htmlElement') elementRef: ElementRef;

    @HostListener('mouseleave')
    onMouseLeave() {
        $('#' + this.id).collapse('hide');
    }
    @HostListener('mouseenter')
    onMouseEnter() {
        $('#' + this.id).collapse('show');
    }
}