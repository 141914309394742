<div class="main-container">
    <div class="header-container p-2">
        <h1>Friends List</h1>
    </div>
    <div class="friend-list-container">
        <button class="friend-container p-2" 
                *ngFor="let friend of friends" 
                (click)="emitFriend(friend)"
                [ngClass]="{'friend-selected': friend === selectedFriend}">
            <i class="fas fa-user px-2"></i>{{friend}}
        </button>
        <button class="add-container p-2">
            <i class="fas fa-plus px-2"></i>Add New Friend
        </button>
    </div>
</div>