<div class="bars-container">
    <a data-toggle="collapse" 
       href="#collapse" 
       role="button" 
       aria-expanded="false" 
       aria-controls="collapse"><i class="fas fa-bars"></i></a>
</div>

<div class="collapse main-collapse" id="collapse">
    <ul>
        <li>
            <div class="collapse-category">
                <a routerLink="/">Home</a>
            </div>

        </li>
        <div class="x-container">
            <a href="#collapse"
            data-toggle="collapse" 
            role="button" 
            aria-expanded="false" 
            aria-controls="collapse"><i class="fas fa-times"></i></a>
        </div>
        <li *ngFor="let links of navigationLinks; let i = index">
            <ng-container *ngIf="links.subLinks?.length > 0; else noSubLinks">
                <div class="collapse-category">
                    <a data-toggle="collapse" 
                       href="#collapse{{i}}" 
                       role="button" 
                       aria-expanded="false" 
                       aria-controls="collapse">{{links.label}}<i class="fa fa-caret-down pl-2"></i></a>
                </div>
                <div class="collapse" id="collapse{{i}}">
                    <ul>
                        <li *ngFor="let link of links.subLinks">
                            <div class="category-item">
                                <a [routerLink]="link.path">{{link.label}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </ng-container>
            <ng-template #noSubLinks>
                <div class="collapse-category">
                    <a [routerLink]="links.path">{{links.label}}</a>
                </div>
            </ng-template>
        </li>
    </ul>
</div>