<div class="row no-gutter">
  <div class="col-12 pr-md-0">
    <div class="card card-invis escape-pod-card">
      <div class="card-header m-0">
        <h1 class="header-text">Preparing For Liftoff!</h1>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <canvas class="escape-pod" id="escape-pod">
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-5 mission-container">
    <h2>Our Mission</h2>
  </div>
</div>
