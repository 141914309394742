import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TableWithFiltersPageComponent } from "./table-with-filters-page.component";
import { TableWithFiltersComponent } from "./table-with-filters.component";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from "@angular/material/paginator";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        ReactiveFormsModule

    ],
    declarations: [
        TableWithFiltersComponent,
        TableWithFiltersPageComponent
    ],
    exports: [
        TableWithFiltersComponent,
        TableWithFiltersPageComponent
    ]
})
export class TableWithFiltersModule {}
