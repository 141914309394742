import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-just-for-fun',
    templateUrl: './just-for-fun.component.html',
    styleUrls: ['./just-for-fun.component.scss']
})
export class JustForFunComponent implements OnInit {
    kanyeRest = 'https://api.kanye.rest/';
    kanyeQuote: string;
    constructor(private http: HttpClient) {
        
    }
    ngOnInit() {
        this.http.get(this.kanyeRest).subscribe((resp) => {
            this.kanyeQuote = resp['quote'];
        });
    }
}