<div class="card">
    <div class="row m-3">
        <div class="col-6" [ngClass]="{'has-error' : formErrors['name']}">
            <label class="field-label" for="name">
                Name
            </label>
            <input [formControl]="form1.controls.name"
                   type="text"
                   class="w-100"
                   id="name"
                   (focus)="clearFieldValidity('name')"
                   (blur)="setFieldValidity(form1.controls.name, 'name')">
            <app-form-error *ngIf="formErrors['name']"
                            [message]="'Please Enter a Name'">
            </app-form-error>
        </div>
        <div class="col-6" [ngClass]="{'has-error' : formErrors['occupation']}">
            <label class="field-label" for="occupation">
                Occupation
            </label>
            <input [formControl]="form1.controls.occupation"
                   type="text"
                   class="w-100"
                   id="occupation"
                   (focus)="clearFieldValidity('occupation')"
                   (blur)="setFieldValidity(form1.controls.occupation, 'occupation')">
            <app-form-error *ngIf="formErrors['occupation']"
                            [message]="'Please Enter an Occupation'">
            </app-form-error>
        </div>
        <hr class="mb-0 mx-3">
        <div class="col-12">
            <app-address-form [formErrors]="formErrors"
                              [formGroup]="addressForm"
                              (clearFieldValidity)="clearFieldValidity($event)"
                              (setFieldValidity)="setFieldValidity($event.control, $event.fieldName)"></app-address-form>
        </div>
    </div>
    <div class="row m-3">
        <div class="col-12">
            <button class="btn-primary" (click)="validateForm(form1)">Validate Form1</button>
        </div>
    </div>
</div>