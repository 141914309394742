import { Component } from "@angular/core";

@Component({
    selector: 'app-chat-app',
    templateUrl: './chat-app.component.html',
    styleUrls: ['./chat-app.component.scss']
})
export class ChatAppComponent {
    selectedFriend: string;
    
    selectFriendToChat(friend: string) {
        this.selectedFriend = friend;
    }
    closeChat() {
        this.selectedFriend = null;
    }
}