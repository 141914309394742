<div class="header-container {{lookAndFeelService.getLookAndFeel()}}">
    <!-- Desktop View -->
    <div class="desktop-container">
        <div class="row lower-container mx-0">
            <h1 class="header-text">
                GalactiCorp
            </h1>
            <div class="options-container">
                <div class="option-container">
                    <a routerLink="/">Home</a>
                </div>
                <ng-container class="option-container" *ngFor="let link of headerLinks; let i = index">
                    <div class="option-container">
                        <app-dropdown-panel [navigationLinks]="link" [id]="'navCollapse' + i"></app-dropdown-panel>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- Mobile View -->
    <div class="mobile-container">
        <h1 class="mobile-header">GalactiCorp</h1>
        <div class="mobile-accordion">
            <app-navbar-accordion [navigationLinks]="headerLinks"></app-navbar-accordion>
        </div>
    </div>
</div>
