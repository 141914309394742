import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from 'rxjs';

@Injectable()
export class AlphaAdvantageService {
    private apiKey = 'XUP5H9JEAH5VVL1Y';
    private urlBase = 'https://www.alphavantage.co/query';

    constructor(private http: HttpClient) {
    }

    public getStocksData(_function: string, symbol: string, outputSize?: string, dataType?: string): Observable<any> {
        let params = new HttpParams;
        let url = this.urlBase + '?function=' + _function + '&symbol=' + symbol + '&apikey=' + this.apiKey;
        if (outputSize) {
            url += '&outputsize=' + outputSize;
        }
        if (dataType) {
            url += '&datatype=' + dataType;
        }
        return this.http.get(url);
    }
}