import { NgModule } from "@angular/core";
import { FormErrorComponent } from "./form-error/form-error.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";

@NgModule({
    declarations: [
        FormErrorComponent,
        LoadingSpinnerComponent
    ],
    exports: [
        FormErrorComponent,
        LoadingSpinnerComponent
    ]
})
export class AppCommonModule {}