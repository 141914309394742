export interface PanelLink {
    label: string;
    path: string;
}

export interface NavigationLinks {
    label: string;
    subLinks?: PanelLink[];
    path?: string;
}

export const HeaderNavigationLinks: NavigationLinks[] = [
    {
        label: 'Products',
        path: 'products',
    },
    {
        label: 'Careers',
        path: 'careers'
    },
    {
        label: 'Stations',
        path: 'stations'
    }
]
