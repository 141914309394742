import { NgModule } from "@angular/core";
import { MainPageComponent } from './main-page.component';
import { LeftRailComponent } from './left-rail/left-rail.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        MainPageComponent,
        LeftRailComponent
    ],
    exports: [
        LeftRailComponent
    ],
    imports: [
        CommonModule
    ]
})
export class MainPageModule {}