import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
    form1: FormGroup;
    addressForm: FormGroup;
    form2: FormGroup;
    form3: FormGroup;
    formErrors = {
        name: false,
        occupation: false,
        street: false,
        city: false,
        state: false,
        zipCode: false
    };

    constructor(private fb: FormBuilder) {
        this.addressForm = fb.group({
            street: ['', Validators.required],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', [Validators.pattern(new RegExp('^\\d{5}$')), Validators.required]]
        });
        this.form1 = fb.group({
            name: ['', Validators.required],
            occupation: ['', Validators.required],
            address: this.addressForm
        });
    }
    setFieldValidity(control: AbstractControl, fieldName: string) {
        this.formErrors[fieldName] = !control.valid;
    }
    clearFieldValidity(fieldName: string) {
        this.formErrors[fieldName] = false;
    }
    validateForm(formGroup: FormGroup) {
        if (!formGroup.valid) {
            Object.keys(formGroup.controls).forEach((key) => {
                if (formGroup.controls[key]['controls']) {
                    this.validateForm(formGroup.controls[key] as FormGroup);
                } else {
                this.formErrors[key] = !formGroup.controls[key].valid;
                }
            });
        }
    }
}
