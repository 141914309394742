import { Component, OnInit } from "@angular/core";
import { AlphaAdvantageService } from '../services/alpha-advantage/alpha-advantage.service';
import * as Highcharts from 'highcharts/highstock';
import { IEXCloudService } from '../services/iex-cloud/iex-cloud.service';
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
    selector: 'app-components-demo',
    templateUrl: './graphs-and-charts.component.html'
})
export class GraphsAndChartsComponent implements OnInit {
    candlestickLoading: boolean;
    gldPrice: string;
    chartData: any;
    Highcharts: typeof Highcharts = Highcharts;
    doughnutChartLabels = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
    doughnutChartData = [];
    doughnutChartForm: FormGroup;
    doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
    };
    constructor(private alphaAdvantageService: AlphaAdvantageService,
                private iexCloudService: IEXCloudService,
                private formBuilder: FormBuilder) {

    }
    ngOnInit() {
        this.doughnutChartForm = this.formBuilder.group({
            downloadSales: 100,
            inStoreSales: 200,
            mailOrderSales: 250
        });
        this.doughnutChartForm.valueChanges.subscribe(() => {
            this.setDoughnutChartData();
        });
        this.doughnutChartForm.updateValueAndValidity();
        this.candlestickLoading = true;
        this.iexCloudService.getStocksData('GLD').subscribe((resp) => {
            this.candlestickLoading = false; 
            const arrangedData = [];
            resp.forEach((day) => {
                const dayData = [];
                dayData.push(new Date(day.date).getTime());
                dayData.push(day.open);
                dayData.push(day.high);
                dayData.push(day.low);
                dayData.push(day.close);
                arrangedData.push(dayData);
            });
            this.chartData = {
                title: {
                    text: 'Sample Stock Candlestick Chart'
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        day: '%e %b %y',
                    }
                },
                series: [{
                    type: 'candlestick',
                    id: 'spdrgld-ohlc',
                    name: 'Sample Stock Data',
                    data: arrangedData
                }]
            }
        });
    }
    setDoughnutChartData() {
        this.doughnutChartData = [
            this.doughnutChartForm.value.downloadSales,
            this.doughnutChartForm.value.inStoreSales,
            this.doughnutChartForm.value.mailOrderSales
        ]
    }
}