import { NgModule } from "@angular/core";
import { KillerComponent } from "./killer/killer.component";

@NgModule({
    declarations: [
        KillerComponent
    ],
    exports: [
        KillerComponent
    ]
})
export class EmbeddedComponentsModule {}