import { NgModule } from "@angular/core";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ComponentsModule } from '../components/components.module';
import { CommonModule } from '@angular/common';
import { LookAndFeelComponent } from "../components/look-and-feel/look-and-feel.component";
import { RouterModule } from "@angular/router";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LookAndFeelComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LookAndFeelComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule
    ]
})
export class LayoutModule {
    
}