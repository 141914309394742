import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './main-page/main-page.component';
import { GraphsAndChartsComponent } from './graphs-and-charts/graphs-and-charts.component';
import { ChatAppComponent } from './components/chat-app/chat-app.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { KillerComponent } from './embedded-components/killer/killer.component';
import { TableWithFiltersPageComponent } from './components/table-with-filters/table-with-filters-page.component';
import { JustForFunComponent } from './just-for-fun/just-for-fun.component';


const routes: Routes = [
  { path: '**', component: MainPageComponent, data: {'title': 'Homepage'} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
