import { Component, OnInit } from "@angular/core";
const gifler: any = window['gifler'];

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
    available = true;

    ngOnInit() {
        gifler('../../assets/gifs/escape-pod.gif').animate('canvas.escape-pod')
    }
}
