<h2 class="mt-3">Address</h2>
<div class="row">
    <div class="col-12" [ngClass]="{'has-error' : formErrors['street']}">
        <label class="field-label" for="street">Street Address</label>
        <input [formControl]="formGroup.controls.street"
               type="text"
               class="w-100" 
               id="street"
               (focus)="clearFieldValidity.emit('street')"
               (blur)="setFieldValidity.emit({control: formGroup.controls.street, fieldName: 'street'})">
        <app-form-error *ngIf="formErrors['street']"
                        [message]="'Please Enter a Street Address'">
        </app-form-error>
    </div>
    <div class="col-xs-12 col-sm-5" [ngClass]="{'has-error' : formErrors['city']}">
        <label class="field-label" for="city">City</label>
        <input [formControl]="formGroup.controls.city"
               type="text"
               class="w-100" 
               id="city"
               (focus)="clearFieldValidity.emit('city')"
               (blur)="setFieldValidity.emit({control: formGroup.controls.city, fieldName: 'city'})">
        <app-form-error *ngIf="formErrors['city']"
                        [message]="'Please Enter a City'">
        </app-form-error>
    </div>
    <div class="col-xs-12 col-sm-4" [ngClass]="{'has-error' : formErrors['state']}">
        <label class="field-label" for="state">State</label>
        <input [formControl]="formGroup.controls.state"
               type="text"
               class="w-100"
               id="state"
               (focus)="clearFieldValidity.emit('state')"
               (blur)="setFieldValidity.emit({control: formGroup.controls.state, fieldName: 'state'})">
        <app-form-error *ngIf="formErrors['state']"
                        [message]="'Please Select a State'">
        </app-form-error>
    </div>
    <div class="col-xs-12 col-sm-3" [ngClass]="{'has-error' : formErrors['zipCode']}">
        <label class="field-label" for="zipCode">Zip Code</label>
        <input [formControl]="formGroup.controls.zipCode"
               type="text"
               class="w-100" 
               id="zipCode"
               maxlength="5"
               (focus)="clearFieldValidity.emit('zipCode')"
               (blur)="setFieldValidity.emit({control: formGroup.controls.zipCode, fieldName: 'zipCode'})">
        <app-form-error *ngIf="formErrors['zipCode']"
                        [message]="'Please Enter a 5 Digit Zip Code'">
        </app-form-error>
    </div>
</div>